import React, { Component } from "react";
import { connect } from "react-redux";

import { forceLogout } from "../../../redux/actions/users";
import { getMeetupEvents } from "../../../redux/actions/events";

import { withRouter } from "react-router-dom";
import { format } from "date-fns";

class Events extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      data: [],
      err: {},
    };
    // this.onLogout = this.onLogout.bind(this);
    // this.toggleMenu = this.toggleMenu.bind(this);
  }

  // onLogout(params) {
  //   console.log("onLogout");
  //   if (this.props.forceLogout().status === 200) {
  //     this.props.history.push("/");
  //   }
  // }

  // toggleMenu(params) {}

  componentDidMount() {
    console.log("events mounted");
    this.props
      .getMeetupEvents({ slug: "" })
      .then((res) => {
        console.log(res);
        this.setState({
          data: res.data,
          loaded: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    if (!this.state.loaded) {
      return <div>Plase wait...</div>;
    }

    return (
      <div className="panel home-panel">
        <div className="flex justify-between items-center p-3 bg-blue-900 text-white rounded-md mb-2">
          <span className="text-lg font-semibold">Events</span>
          <div
            className="bg-blue-700 cursor-pointer p-2 text-sm rounded-md select-none"
            onClick={() => {
              this.props.history.push("/dashboard/event-meetup/add");
            }}
          >
            Add Event
          </div>
        </div>
        <div className="flex-row w-full flex-wrap grid-cols-4 grid gap-4 mb-6">
          {this.state.data?.map((item) => {
            return (
              <div
                key={item.id}
                className="container bg-gray-50 p-2 rounded-md shadow"
              >
                <div className="flex flex-col gap-1">
                  {item.is_active ? (
                    <div className="w-fit bg-green-600 text-xs px-2 py-1 text-white rounded-xl">
                      Published
                    </div>
                  ) : (
                    <div className="w-fit bg-zinc-700 text-xs px-2 py-1 text-white rounded-xl">
                      Draft
                    </div>
                  )}

                  <div className="text-sm">
                    {format(new Date(item.start_date), "dd MMM yyyy | p")}
                  </div>
                  <div
                    className="font-bold cursor-pointer"
                    onClick={() => {
                      this.props.history.push("/dashboard/event/" + item.slug);
                    }}
                  >
                    {item.title}
                  </div>
                  <div className="text-sm min-h-16">{item.caption}</div>
                  <div className="text-sm">Venue : {item.venue}</div>
                  <div className="text-xs capitalize p-1 bg-zinc-500 text-white w-fit rounded select-none">
                    {item.province.name}
                  </div>
                  <div className="text-xs capitalize p-1 bg-zinc-500 text-white w-fit rounded select-none">
                    {item.city.name}
                  </div>
                  {item.is_ticket ? (
                    <div className="bg-blue-500 text-xs text-white w-fit p-1 px-3 align-middle rounded select-none">
                      Ticket Available
                    </div>
                  ) : (
                    <div className="bg-red-500 text-xs text-white w-fit p-1 px-3 align-middle rounded select-none">
                      No Ticket
                    </div>
                  )}
                </div>
                <div className="flex flex-row gap-1">
                  <div
                    className="bg-green-700 text-sm text-white min-w-16 text-center w-fit p-1 px-3 align-middle rounded mt-2 select-none cursor-pointer"
                    onClick={() => {
                      this.props.history.push("/dashboard/event/" + item.slug);
                    }}
                  >
                    View
                  </div>
                  <div
                    className="bg-orange-600 text-sm text-white min-w-16 text-center w-fit p-1 px-3 align-middle rounded mt-2 select-none cursor-pointer"
                    onClick={() => {
                      this.props.history.push(
                        "/dashboard/event/edit/" + item.slug
                      );
                    }}
                  >
                    Edit
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    forceLogout: (data) => dispatch(forceLogout(data)),
    getMeetupEvents: (data) => dispatch(getMeetupEvents(data)),
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Events));

import React, { Component } from "react";
import { connect } from "react-redux";

import { forceLogout } from "../redux/actions/users";

import { NavLink, withRouter } from "react-router-dom";
import { Divider } from "@mui/material";

class SideMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //   account_type: 0,
      //   mobileMenu: false,
      //   logoIcon: "",
      err: null,
    };

    this.onLogout = this.onLogout.bind(this);
    // this.toggleMenu = this.toggleMenu.bind(this);
  }

  onLogout(params) {
    console.log("onLogout");
    if (this.props.forceLogout().status === 200) {
      this.props.history.push("/");
    }
  }

  // toggleMenu(params){

  // }

  // componentDidMount() {
  //   console.log("header mounted");
  // }

  render() {
    return (
      <ul className="sidemenu-ul">
        <li>
          <NavLink activeClassName="active" to="/dashboard/home">
            Home
          </NavLink>
        </li>
        <li>
          <NavLink activeClassName="active" to="/dashboard/news">
            News
          </NavLink>
        </li>
        <li>
          <NavLink activeClassName="active" to="/dashboard/sliders">
            Sliders
          </NavLink>
          {/* <ul className="menu-sub-item transition-all duration-1000">
            <li>
              <NavLink activeClassName="active" to="/dashboard/sliders/home">
                Home
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName="active" to="/dashboard/sliders/event">
                Events
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName="active" to="/dashboard/sliders/marketplace">
                Marketplace
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName="active" to="/dashboard/sliders/garage">
                Garage
              </NavLink>
            </li>
          </ul> */}
        </li>
        <Divider />
        <li>
          <NavLink activeClassName="active" to="/dashboard/events">
            Events
          </NavLink>
        </li>
        <li>
          <NavLink activeClassName="active" to="/dashboard/events-meetup">
            Events Meetup
          </NavLink>
        </li>
        <Divider />
        <li>
          <NavLink activeClassName="active" to="/dashboard/users">
            Users
          </NavLink>
        </li>
        {/* <li>
          <NavLink activeClassName="active" to="/dashboard/promotions">
            Promotions
          </NavLink>
        </li> */}

        {/* <li>
          <NavLink activeClassName="active" to="/dashboard/event-categories">
            Event Categories
          </NavLink>
        </li> */}
        <Divider />
        <li>
          <NavLink activeClassName="active" to="/dashboard/stores">
            Stores
          </NavLink>
        </li>
        <li>
          <NavLink activeClassName="active" to="/dashboard/products">
            Products
          </NavLink>
        </li>
        <li>
          <NavLink activeClassName="active" to="/dashboard/product-categories">
            Product Categories
          </NavLink>
        </li>
        <Divider />
        <li>
          <NavLink activeClassName="active" to="/dashboard/clubs">
            Clubs
          </NavLink>
        </li>
        <Divider />
        <li>
          <NavLink activeClassName="active" to="/dashboard/rides">
            Rides
          </NavLink>
        </li>
        <li>
          <NavLink activeClassName="active" to="/dashboard/routes">
            Routes
          </NavLink>
        </li>
        <li>
          <NavLink activeClassName="active" to="/dashboard/challenges">
            Challenges
          </NavLink>
        </li>
        <Divider />
        <li>
          <NavLink activeClassName="active" to="/dashboard/analytics">
            Analytics
          </NavLink>
        </li>
        <li>
          <NavLink activeClassName="active" to="/dashboard/reports">
            Reports
          </NavLink>
        </li>
        <Divider />
        <li>
          <NavLink activeClassName="active" to="/dashboard/provinces">
            Provinces
          </NavLink>
        </li>
        <li>
          <NavLink activeClassName="active" to="/dashboard/settings">
            Settings
          </NavLink>
        </li>
      </ul>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    forceLogout: (data) => dispatch(forceLogout(data)),
    //logout: data => dispatch(logout(data))
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SideMenu));

import React, { Component } from "react";
import { connect } from "react-redux";

import { Switch, MenuItem, TextField, FormControlLabel } from "@mui/material";

// import { createNews, getNewsCategories } from "../../../redux/actions/news";
import { getEventDetail, updateMeetupEvent } from "../../../redux/actions/events";
import { getProvinces, getCities } from "../../../redux/actions/geo";

import Files from "react-files";

import { withRouter } from "react-router-dom";

class EventEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      image: undefined,
      imageUrl: undefined,
      dataCities: [],
      dataProvinces: [],
      dataCategories: [],
      data: {
        id: 0,
        idcategory: 1,
        slug: undefined,
        title: undefined,
        caption: undefined,
        content: undefined,
        idcity: undefined,
        idprovince: undefined,
        disable_comment: true,
        is_ticket: false,
        is_active: true,
        // slug: undefined,
        // title: undefined,
        // caption: undefined,
        // content: undefined,
        // idcategory: undefined,
        // is_active: true,
      },
      err: {},
    };

    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleFileError = this.handleFileError.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.loadCity = this.loadCity.bind(this);
    this.loadProvince = this.loadProvince.bind(this);
  }

  loadCity = (idprovince, first = false) => {
    this.props
      .getCities({ idprovince: idprovince, slug: "all" })
      .then((res) => {
        if (res.code === 200) {
          this.setState(
            {
              data: {
                ...this.state.data,
                idcity: first === false ? undefined : this.state.data.idcity,
              },
            },
            () => {
              this.setState({
                dataCities: res.data,
                loaded: true,
              });
            }
          );
        }
      });
  };

  loadProvince = () => {
    this.props.getProvinces({ slug: "all" }).then((res) => {
      if (res.code === 200) {
        console.log(res.data);
        this.setState({
          dataProvinces: res.data,
          // loaded: true,
          // data: {
          //   ...this.state.data,
          //   dataProvinces: res.data,
          // },
        });
      }
    });
  };

  handleFileChange = (files) => {
    console.log("handleFileChange");
    console.log(files);

    this.setState({
      image: files[0],
      imageUrl: files[0].preview.url,
    });
  };

  handleFileError = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };

  handleChange(e) {
    if (e.target.name === "idprovince") {
      this.loadCity(parseInt(e.target.value));
    }

    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]:
          e.target.name === "slug"
            ? e.target.value.replaceAll(" ", "-").toLowerCase().trim()
            : e.target.value,
      },
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    console.log(this.state.data);
    console.log(this.state.image);

    this.props
      .updateMeetupEvent({
        id: this.state.data.id,
        title: this.state.data.title,
        caption: this.state.data.caption,
        content: this.state.data.content,
        idprovince: this.state.data.idprovince,
        idcity: this.state.data.idcity,
        idcategory: this.state.data.idcategory,
        latlon: this.state.data.latlon,
        venue: this.state.data.venue,
        is_ticket: this.state.data.is_ticket,
        disable_comment: this.state.data.disable_comment,
        is_active: this.state.data.is_active,
        image: this.state.image !== null ? this.state.image : undefined,
      })
      .then((res) => {
        if (res.code === 200) {
          window.location.reload(false);
          alert("Update Success!");
        } else {
          alert("Something Error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidMount() {
    this.props
      .getEventDetail({ slug: this.props.match.params.slug })
      .then((res) => {
        console.log(res);
        this.setState(
          {
            data: res.data,
            // loaded: true,
          },
          () => {
            this.loadProvince();
            this.loadCity(res.data.idprovince, true);
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });

    // this.props
    //   .getNewsCategories()
    //   .then((res) => {
    //     console.log(res);
    //     this.setState({
    //       dataCategories: res.data,
    //       loaded: true,
    //     });
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }

  render() {
    if (!this.state.loaded) {
      return <div>Plase wait...</div>;
    }

    return (
      <div className="panel home-panel">
        <div className="flex justify-between items-center p-3 bg-blue-900 text-white rounded-md mb-2">
          <span className="text-lg font-semibold">Edit Event</span>
        </div>
        <form
          className="w-full flex flex-col gap-3 pt-2"
          onSubmit={this.handleSubmit}
        >
          <div className="w-1/2">
            <Files
              className="files-dropzone"
              onChange={this.handleFileChange}
              onError={this.handleFileError}
              accepts={["image/*"]}
              multiple={false}
              maxFileSize={10000000}
              minFileSize={0}
              clickable
            >
              {/*               
              {this.state.data.imageurl === null ? (
                <span>Image not found</span>
              ) : (
                <img
                  src={this.state.data.imageurl}
                  alt={"thumbnail-" + this.state.data.title}
                  className="cursor-pointer news-item-thumbnail w-full hover:grayscale-0 "
                />
              )} */}

              {/* ? this.state.imageUrl : this.state.data.imageurl */}

              {/* {this.state.imageUrl ? (
                <img
                  src={this.state.imageUrl}
                  alt={"thumbnail-" + this.state.data.title}
                  className="cursor-pointer news-item-thumbnail w-full hover:grayscale-0 "
                />
              ) : (
                ""
              )}
              <span className="cursor-pointer text-center block w-full p-2">
                Drop files here or click to upload
              </span> */}

              {this.state.image ? (
                <img
                  src={
                    this.state.imageUrl
                      ? this.state.imageUrl
                      : this.state.data.imageurl
                  }
                  alt={"thumbnail-" + this.state.data.title}
                  className="cursor-pointer news-item-thumbnail w-full hover:grayscale-0 "
                />
              ) : (
                <img
                  src={this.state.data.imageurl}
                  alt={"thumbnail-" + this.state.data.title}
                  className="cursor-pointer news-item-thumbnail w-full hover:grayscale-0 "
                />
              )}

              <span className="cursor-pointer text-center block w-full p-2">
                Drop files here or click to upload
              </span>

              {/* <div
                className={
                  "cursor-pointer text-center block w-full p-2 text-sm " +
                  (this.state.image === undefined
                    ? "h-64 border border-zinc-300 rounded flex align-middle justify-center items-center"
                    : "")
                }
              >
                Drop image here or click to upload
              </div> */}
            </Files>
          </div>
          {/* <TextField
            required
            select
            id="select"
            size="small"
            label="Category"
            name="idcategory"
            className="text-xs"
            value={this.state.data.idcategory || ""}
            onChange={this.handleChange}
          >
            {this.state.dataCategories.map((item) => {
              return (
                <MenuItem value={item.id} key={item.id}>
                  {item.title}
                </MenuItem>
              );
            })}
          </TextField> */}
          <TextField
            required
            id="title"
            name="title"
            type="text"
            label="Title"
            value={this.state.data.title || ""}
            onChange={this.handleChange}
          />
          <TextField
            required
            disabled
            id="slug"
            name="slug"
            type="text"
            label="Slug"
            value={this.state.data.slug || ""}
            onChange={this.handleChange}
          />
          <TextField
            required
            select
            id="select"
            size="small"
            label="Province"
            name="idprovince"
            className="text-xs capitalize"
            value={this.state.data.idprovince || ""}
            onChange={this.handleChange}
          >
            {this.state.dataProvinces.map((item) => {
              return (
                <MenuItem value={item.id} key={item.id} className="capitalize">
                  {item.name}
                </MenuItem>
              );
            })}
          </TextField>
          <TextField
            required
            select
            id="select"
            size="small"
            label="City"
            name="idcity"
            className="text-xs capitalize"
            value={this.state.data.idcity || ""}
            onChange={this.handleChange}
          >
            {this.state.dataCities.map((item) => {
              return (
                <MenuItem value={item.id} key={item.id} className="capitalize">
                  {item.name}
                </MenuItem>
              );
            })}
          </TextField>
          <TextField
            required
            id="venue"
            name="venue"
            type="text"
            label="Venue"
            value={this.state.data.venue || ""}
            onChange={this.handleChange}
          />
          <TextField
            required
            id="latlon"
            name="latlon"
            type="text"
            label="Latitude, Longitude"
            placeholder="-6.779999, 107.634180"
            value={this.state.data.latlon || ""}
            onChange={this.handleChange}
          />
          <TextField
            required
            id="caption"
            name="caption"
            type="text"
            label="Caption"
            multiline
            rows={2}
            value={this.state.data.caption}
            onChange={this.handleChange}
          />
          <TextField
            required
            id="content"
            name="content"
            type="text"
            label="Content"
            multiline
            rows={5}
            value={this.state.data.content}
            onChange={this.handleChange}
          />

          <FormControlLabel
            control={<Switch checked={this.state.data.is_ticket} />}
            label={<span style={{ fontSize: "14px" }}>Ticket</span>}
            name="is_ticket"
            onChange={() => {
              this.setState({
                data: {
                  ...this.state.data,
                  is_ticket: !this.state.data.is_ticket,
                },
              });
            }}
            className="w-fit"
          />

          <FormControlLabel
            control={<Switch checked={this.state.data.disable_comment} />}
            label={<span style={{ fontSize: "14px" }}>Disable Comment</span>}
            name="disable_comment"
            onChange={() => {
              this.setState({
                data: {
                  ...this.state.data,
                  disable_comment: !this.state.data.disable_comment,
                },
              });
            }}
            className="w-fit"
          />

          <FormControlLabel
            control={<Switch checked={this.state.data.is_active} />}
            label={<span style={{ fontSize: "14px" }}>Active</span>}
            name="is_active"
            onChange={() => {
              this.setState({
                data: {
                  ...this.state.data,
                  is_active: !this.state.data.is_active,
                },
              });
            }}
            className="w-fit"
          />

          <div className="flex-0">
            <input
              type="submit"
              title="Update"
              name="Update"
              value="Update"
              // label="publish"
              className="text-sm w-fit p-2 bg-blue-800 text-white rounded cursor-pointer hover:bg-blue-900 min-w-16 text-center"
            />
          </div>
        </form>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getCities: (data) => dispatch(getCities(data)),
    getProvinces: (data) => dispatch(getProvinces(data)),
    updateMeetupEvent: (data) => dispatch(updateMeetupEvent(data)),
    getEventDetail: (data) => dispatch(getEventDetail(data)),
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EventEdit)
);

import React, { Component } from "react";
import { connect } from "react-redux";

import { updateNews, getNewsDetail, getNewsCategories } from "../../../redux/actions/news";
import { Switch, MenuItem, TextField, FormControlLabel } from "@mui/material";
import Files from "react-files";

import { withRouter } from "react-router-dom";

class NewsEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      dataCategories: [],
      image: null,
      imageUrl: null,
      loaded: false,
      err: {},
    };

    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleFileError = this.handleFileError.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleFileChange = (files) => {
    this.setState({
      image: files[0],
      imageUrl: files[0].preview.url,
    });
  };

  handleFileError = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };

  handleChange(e) {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]:
          e.target.name === "slug"
            ? e.target.value.replaceAll(" ", "-").toLowerCase().trim()
            : e.target.value,
      },
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props
      .updateNews({
        slug: this.props.match.params.slug,
        title: this.state.data.title,
        caption: this.state.data.caption,
        content: this.state.data.content,
        idcategory: this.state.data.idcategory,
        is_active: this.state.data.is_active,
        image: this.state.image !== null ? this.state.image : undefined,
      })
      .then((res) => {
        if (res.code === 200) {
          window.location.reload(false);
          alert("Update Success!");
        } else {
          alert("Something Error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidMount() {
    console.log(this.props.match.params.slug);
    this.props.getNewsCategories().then((res) => {
      this.setState(
        {
          dataCategories: res.data,
        },
        () => {
          this.props
            .getNewsDetail({ slug: this.props.match.params.slug })
            .then((res) => {
              if (res.code === 200) {
                this.setState({
                  data: res.data,
                  loaded: true,
                });
              } else {
                alert("Something Error");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      );
    });
  }

  render() {
    if (!this.state.loaded) {
      return <div className="w-full h-full items-center justify-center align-middle">Plase wait...</div>;
    }

    return (
      <div className="panel home-panel">
        <div className="flex justify-between items-center p-3 bg-blue-900 text-white rounded-md mb-2">
          <span className="text-lg font-semibold">Edit News</span>
        </div>
        <form className="w-full flex flex-col gap-3 pt-2" onSubmit={this.handleSubmit}>
          <TextField
            required
            disabled
            id="slug"
            name="slug"
            type="text"
            label="Slug"
            value={this.state.data.slug}
          />
          <div className="w-1/2">
            <Files
              className="files-dropzone"
              onChange={this.handleFileChange}
              onError={this.handleFileError}
              accepts={["image/*"]}
              // accepts={["image/png", ".pdf", "audio/*"]}
              multiple={false}
              maxFileSize={10000000}
              minFileSize={0}
              clickable
            >
              <img
                src={this.state.imageUrl ? this.state.imageUrl : this.state.data.imageurl}
                alt={"thumbnail-" + this.state.data.title}
                className="cursor-pointer news-item-thumbnail w-full hover:grayscale-0 "
              />
              <span className="cursor-pointer text-center block w-full p-2">
                Drop files here or click to upload
              </span>
            </Files>
          </div>

          <TextField
            select
            id="select"
            label="Category"
            // value="20"
            name="idcategory"
            size="small"
            className="text-xs"
            value={this.state.data.idcategory}
            onChange={this.handleChange}
          >
            {this.state.dataCategories.map((item) => {
              return (
                <MenuItem value={item.id} key={item.id}>
                  {item.title}
                </MenuItem>
              );
            })}
          </TextField>

          <TextField
            required
            id="title"
            name="title"
            type="text"
            label="Title"
            value={this.state.data.title}
            onChange={this.handleChange}
          />
          <TextField
            id="content"
            name="content"
            type="text"
            label="Content"
            multiline
            rows={10}
            value={this.state.data.content}
            onChange={this.handleChange}
          />

          <FormControlLabel
            control={<Switch checked={this.state.data.is_active} />}
            label={<span style={{ fontSize: "14px" }}>Active</span>}
            name="is_active"
            onChange={() => {
              this.setState({
                data: {
                  ...this.state.data,
                  is_active: !this.state.data.is_active,
                },
              });
            }}
            className="w-fit"
          />

          <div className="flex-0">
            <input
              type="submit"
              className="text-sm w-fit p-2 bg-blue-800 text-white rounded cursor-pointer hover:bg-blue-900 min-w-16 text-center"
              label="publish"
            />
          </div>
        </form>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateNews: (data) => dispatch(updateNews(data)),
    getNewsDetail: (data) => dispatch(getNewsDetail(data)),
    getNewsCategories: (data) => dispatch(getNewsCategories(data)),
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewsEdit));

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { checkLogin } from "./redux/actions/users";

import Header from "./components/header";
// import Footer from "./components/footer";

import Login from "./pages/login";
import Dashboard from "./pages/dashboard";

import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

//import logo from './logo.svg';
import "./input.css";
import "./output.css";
import "./index.css";
import "./App.css";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: false,
      isLoaded: false,
      err: {
        message: "",
        code: 0,
      },
    };
  }

  componentDidMount() {
    console.log("APP.JS");
    console.log(this.props);

    // if (window.localStorage.getItem("app_tt") !== null) {
    //   this.props.checkLogin().then((res) => {
    //     if (res.code === 200) {
    //       //console.log(this.props.isLogin);
    //       console.log("checkLogin");
    //       console.log(res);
    //       // ?.state?.from?.pathname
    //       // console.log(location);
    //     }
    //   });
    // }
  }

  render() {
    return (
      <div className="min-h-screen">
        <Router basename="/">
          <Header />
          <>
            <Switch>
              <PrivateRoute loginState={this.props.isLogin} path="/dashboard">
                <Dashboard></Dashboard>
              </PrivateRoute>
              <Route exact path="/login">
                <Login></Login>
              </Route>
              <Route exact path="/about">
                <>About Page</>
              </Route>
              <Route path="/">
                {/* <>Welcome...</> */}
                <Redirect to="/dashboard"></Redirect>
              </Route>
            </Switch>
          </>
          {/* <Footer /> */}
        </Router>
      </div>
    );
  }
}

function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        rest.loginState !== false ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

const mapStateToProps = (state) => ({
  ...state,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      checkLogin,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);

// npx tailwindcss -i ./src/input.css -o ./src/output.css --watch
// npx tailwindcss -i ./src/input.css -o ./src/output.css --watch